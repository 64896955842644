import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				PureClean
			</title>
			<meta name={"description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:title"} content={"PureClean"} />
			<meta property={"og:description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="#ffffff" quarkly-title="Contacts-2">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px auto 18px auto"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="center"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
				max-width="50%"
			>
				Почувствуйте разницу PureClean
			</Text>
			<Text
				margin="0px 20% 50px 20%"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Свяжитесь с нами сегодня, чтобы запланировать обслуживание и позвольте нам подобрать лучшее в вашем автомобиле. Потому что в PureClean мы верим, что каждый автомобиль заслуживает того, чтобы выглядеть как можно лучше.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				align-items="center"
				lg-align-items="flex-start"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="#561dc6"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Qanysh Satbaev St 18, Astana 020000, Kazakhstan
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							Посетите нас
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdEmail}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="#561dc6"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							hello@lumaxora.com
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							Напишите нам
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="#561dc6"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							+77073716174
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							Звоните нам
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2">
				Qanysh Satbaev St 18, Astana 020000, Kazakhstan
			</Override>
			<Override slot="link3">
				hello@lumaxora.com
			</Override>
			<Override slot="link4">
				+77073716174
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});